/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useContext } from 'react';
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useIdleTimer } from 'react-idle-timer';
import { LanguageContext } from './language'

// import "../../src/styles/animations.css";

// import * as FontStyles from "./src/fonts/fonts.css"

const Layout = ({ children, className, homePath, showLanguageButtons, styles, timeoutOverride }) => {
  const { language, setLanguage } = useContext(LanguageContext);

  const timeoutSeconds = (timeoutOverride) => {
    var myTimeout = parseInt(timeoutOverride);
    if (isNaN(myTimeout)) {
      myTimeout = 180; // default
    }
    // console.log("setting timeout " + myTimeout)
    return myTimeout;
  }

  const handleOnIdle = event => {
    if (homePath) {
      window.location.pathname = homePath;
    } else {
      window.location.reload();
    }
  }

  const handleOnActive = event => {
    console.log('user is active', event)
    console.log('time remaining', getRemainingTime())
  }

  const handleOnAction = (e) => {
    console.log('user did something', e)
  }

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * timeoutSeconds(timeoutOverride),
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  })

  const changeLanguage = (lang) => {
    setLanguage(lang);
    if (lang === "maori") {
      [].forEach.call(document.getElementsByClassName("maoriText"), function (el) {
        el.classList.remove("hidden");
        el.setAttribute('style', 'animation: fadeIn 0.8s ease forwards;');
      });
      [].forEach.call(document.getElementsByClassName("englishText"), function (el) {
        el.classList.add("hidden");
        el.setAttribute('style', 'animation: none');
      });
    } else {
      [].forEach.call(document.getElementsByClassName("maoriText"), function (el) {
        el.classList.add("hidden");
        el.setAttribute('style', 'animation: none');
      });
      [].forEach.call(document.getElementsByClassName("englishText"), function (el) {
        el.classList.remove("hidden");
        el.setAttribute('style', 'animation: fadeIn 0.8s ease forwards;');
      });
    }
  }

  var languageButtons = <div id="buttons-language" className={"buttons-language " + (showLanguageButtons ? "" : "hidden")} style={{"animation": (showLanguageButtons ? "animation: fadeIn 0.8s ease forwards" : "none")}}>
    <a className={language == "english" ? "active" : ""} onClick={() => {changeLanguage("english")}}>ENGLISH</a>
    <a className={language == "maori" ? "active" : ""} onClick={() => {changeLanguage("maori")}}>TE REO</a>
  </div>

  return (
    <>
      <style>
        {".buttons-language {top:60px;right:0;position:fixed;z-index:99999999;font-size:18pt;}" }
        {".buttons-language a {display:inline-block;width:180px;background-color:#b7b7b7;#color:#000;line-height:80px;text-align:center;}" }
        {".buttons-language a.active {background-color:#000000;color:#cfcfce;}" }
        {styles}
      </style>
      <main className={className}>
        { languageButtons }
        { children }
      </main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Layout
